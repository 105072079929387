import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import tw, { styled } from 'twin.macro'

const StyledCard = styled.div`
  & > a {
    min-height: 250px;
    ${tw`bg-light rounded-xl p-6 text-base block text-navy hover:relative hover:z-10 hover:shadow-lg hover:scale-105 transform transition ease-in duration-300`}
  }
  .gatsby-image-wrapper {
    ${tw`xl:float-left 2xl:mr-4`}
  }
`

const Card = ({ children, contact }) => {
  return (
    <StyledCard>
      {typeof window !== 'undefined' ? (
        <a
          className="contact"
          href={
            contact.email
              ? `mailto:${contact.email}`
              : `mailto:post@stmvinduer.dk`
          }
        >
          {children}
        </a>
      ) : (
        <p className="contact">{children}</p>
      )}
    </StyledCard>
  )
}

const ContactComponent = ({ contact }) => {
  const image = getImage(contact.bild[0]?.localFile)
  return (
    <Card contact={contact}>
      {image && (
        <GatsbyImage
          image={image}
          css={tw`rounded-xl mr-6`}
          alt={`kontakt ` + contact.bild[0].title}
          aspectratio={1 / 1}
          transformoptions={{
            fit: 'cover',
            cropFocus: 'attention',
            position: 'top',
          }}
        />
      )}
      <div css={tw`overflow-hidden`}>
        <h4 css={tw`my-0 leading-none text-stm pt-6`}>{contact.title}</h4>
        <div css={tw`text-sm leading-normal mb-2 italic`}>
          {contact.jobTitle}
        </div>
        <p css={tw`my-0 text-stm`}>
          {typeof window !== 'undefined' && contact.email}
        </p>
        {contact.telefon && (
          <p css={tw`my-0 whitespace-nowrap`}>Tel {contact.telefon}</p>
        )}
        {contact.mobil && (
          <p css={tw`my-0 whitespace-nowrap`}>Mob {contact.mobil}</p>
        )}
      </div>
    </Card>
  )
}

export default function Contacts({ content }) {
  /* eslint-disable */
  const [search, setSearch] = useState('')

  return (
    <section css={tw`container md:px-10 px-6 -mt-6`}>
      <div className="results" css={tw`my-8 border-t-4 border-stm pt-2`}>
        <div
          className="contacts"
          css={tw`grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-4`}
        >
          {content.show
            .filter((contact) => {
              return (
                contact.title.toLowerCase().includes(search.toLowerCase()) ||
                contact.jobTitle.toLowerCase().includes(search.toLowerCase())
              )
            })
            .map((contact, i) => (
              <ContactComponent key={`contact-${i}`} contact={contact} />
            ))}
        </div>
      </div>
    </section>
  )
}
